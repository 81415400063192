/** @jsxRuntime classic */
/** @jsx jsx */

import { css, jsx } from "@emotion/react";
import {
  Button,
  ContentSection,
  Heading,
  Spacer,
  Text,
} from "@zapier/design-system";
import Head from "next/head";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { IframePageLayout } from "src/components/IframePageLayout";
import { useQueryParam } from "src/utils/useQueryParam";

const styleColumnCenter = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
//
// THIS IS PART OF OUR PUBLIC WORKFLOW API. DO NOT CHANGE THIS IN BACKWARDS
// INCOMPATIBLE WAYS.
//
// https://docs.api.zapier.com/guides/getting-started/Selecting-an-Authentication
//
// !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
export interface PopupCloseEvent {
  type: PopupCloseEventType;
  authentication_id?: string;
}

export const popupCloseEventType = "zapier.popup.close";
export type PopupCloseEventType = typeof popupCloseEventType;

// http://localhost:3000/partner/popup-close
export default function PopupClose() {
  const router = useRouter();
  const queryAuthenticationId = useQueryParam("authentication_id");

  useEffect(() => {
    if (!(window.opener && router.isReady)) {
      return;
    }

    const message: PopupCloseEvent = { type: popupCloseEventType };
    if (queryAuthenticationId) {
      message.authentication_id = queryAuthenticationId;
    }
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    //
    // This needs to be allowed for ALL domains because this is used by the
    // Workflow API. Technically we could do some kind of allow list behavior
    // here but it would be pretty challenging since we would have to
    // enumerate the origins we're sending this message to. Considering this
    // is NOT especially sensitive information, we're not going to bother with
    // that level of security right now.
    //
    // !!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!!
    window.opener.postMessage(message, "*");

    // This currently seems to work in Chrome, Safari, and Firefox. Supposedly
    // a window shouldn't be able to close itself, only other windows opened
    // using `window.open()`, but that just doesn't seem to be true any more.
    // I swear some time in the past few years Firefox didn't support this
    // usage, but it's totally working now! Given that the COOP (cross origin
    // opener policy) prevents retaining access to this window, we must close
    // ourselves or else users will have to manually close the popup window...
    //
    // https://developer.mozilla.org/en-US/docs/Web/API/Window/close
    //
    window.close();
  }, [router.isReady, queryAuthenticationId]);

  return (
    <IframePageLayout>
      <Head>
        <title>Close this page to continue | Zapier</title>
      </Head>
      <ContentSection>
        <Text tag={Heading} type="PageHeader" textAlign="center">
          Close this page to continue
        </Text>
        <Spacer height={20} />
        <div css={styleColumnCenter}>
          <Button
            size="large"
            onClick={() => {
              window.close();
            }}
          >
            Continue
          </Button>
        </div>
      </ContentSection>
    </IframePageLayout>
  );
}
